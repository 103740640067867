@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap');
@import "./colours";

body,
html {
  background-color: $grey-1;
  font-size: 16px;
  padding: 12px;
}

body,
html,
input,
button,
select {
  font-family: "Karla", Futura, sans-serif;
}

.hide-on-print {
  @media print {
    display: none !important;
  }
}

@page {
  size: 210mm 297mm;
  /* Chrome sets own margins, we change these printer settings */
  margin: 0;
}

.id {
  color: $grey-4;
  font-style: italic;
  font-size: 12px;
}

.invalid {
  .to-strikethrough {
    color: $grey-4;
    text-decoration: line-through;
  }

  td {
    background-color: $grey-1;
  }
}

.loading {
  width: 100%;
  text-align: center;
  padding-top: 32px;
  svg {
    font-size: 24px;
    opacity: 0.3;
  }
}

.input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  label {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 14px;
    color: $grey-4;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"],
  select {
    font-size: 14px;
    padding: 10px;
    border: 0;
    border-bottom: 3px solid $grey-2;
    flex: 1;
  }
}

.input-group-row {
  flex-direction: row;
}

button,
input[type="submit"] {
  background-color: $primary-5;
  border: 0;
  padding: 10px 24px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-top: 0 solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid $primary-7;
  transition: 0.1s all;
  cursor: pointer;
  top: 0;
  position: relative;
}

button:hover,
input[type="submit"]:hover {
  top: 3px;
  // border-top: 3px solid $primary-8;
  background-color: $primary-6;
  border-bottom: 3px solid $grey-1;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

button:disabled {
  background-color: $grey-3;
  border-bottom: 0;
  cursor: not-allowed;
  &:hover {
    top: 0;
    background-color: $grey-2;
    box-shadow: none;
  }
}

.btn-secondary {
  background-color: $grey-1;
  border-bottom: 3px solid $grey-3;
  color: $primary-7;
}

.btn-secondary:hover {
  top: 3px;
  border-bottom: 3px solid white;
  background-color: $grey-2;
}

.btn-warn {
  background-color: $red-6;
  border-bottom: 3px solid $red-8;
  color: $red-1;
}

.btn-warn:hover {
  border-bottom: 3px solid white;
  background-color: $red-7;
}

#login-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  .logo {
    width: 100%;
    max-width: 120px;
    margin: 24px 0;
  }
}

#user {
  margin: 0 auto;
  max-width: 600px;

  #header-bar {
    display: flex;
    flex-direction: row;
    align-items: center;

    .navbar-link {
      font-size: 12px;
      letter-spacing: 1px;
    }
  }

  .waiting-text {
    padding: 12px;
    text-align: center;
    font-size: 18px;
    color: $grey-4;

    h1 {
      font-size: 28px;
      margin-bottom: 8px;
    }
  }

  #voting-page {
    #currently-voting-for {
      margin-top: 24px;

      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: $grey-4;
      font-size: 14px;

      margin-bottom: 24px;

      #position {
        font-size: 36px;
        color: $primary-9;
        text-transform: none;
        letter-spacing: 0;
      }
    }

    #options {
      margin: 24px 0;
      .option {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 6px;

        input {
          margin-right: 12px;
          padding: 4px;
        }

        label {
          font-size: 18px;
        }

        #no-conf-label,
        #abstain-label {
          font-size: 16px;
          font-style: italic;
          color: $grey-5;
        }
      }
    }

    button {
      width: 100%;
    }
  }
}

table {
  margin: 24px auto;
  width: 100%;
  border-collapse: collapse;

  @media screen {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  th {
    padding: 18px 18px;
    background-color: $grey-2;
    color: $primary-9;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0;
    font-size: 14px;

    border-bottom: 2px solid $grey-3;
  }

  td {
    padding: 6px 18px;
    background-color: white;
    border-bottom: 1px solid $grey-2;
  }
}

#admin {
  #navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    margin-bottom: 24px;

    .logo {
      margin-right: 24px;
    }
  }

  #voters-page,
  #ballots-page {
    width: 100%;
    margin: 0 auto;

    .tbl-btns {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      gap: 8px;
    }
  }

  #voters-page {
    max-width: 900px;

    .invalidated-on {
      color: $grey-4;
      font-size: 12px;
    }
  }

  #voters-table {
    td {
      @media print {
        padding: 18px;
      }
    }
  }

  #ballots-page {
    ul {
      padding-left: 0;
    }

    .status-ongoing,
    .status-pending,
    .status-completed,
    .status-invalidated,
    .vote-status-normal,
    .vote-status-abstain,
    .vote-status-no-confidence {
      font-size: 12px;
      padding: 6px 12px;
      border-radius: 24px;
      display: inline-block;
      margin: 0 6px 4px 0;
    }

    .vote-status-normal,
    .vote-status-abstain,
    .vote-status-no-confidence {
      margin: 4px 0;
    }

    .status-ongoing,
    .vote-status-normal {
      background-color: $primary-2;
      color: $primary-9;
    }

    .status-pending {
      background-color: $red-1;
      border-bottom: 2px solid $red-2;
      color: $red-5;
    }

    .status-ongoing {
      border-bottom: 2px solid $primary-3;
    }

    .status-completed,
    .vote-status-abstain {
      background-color: $grey-2;
      color: $grey-9;
    }

    .status-invalidated,
    .vote-status-no-confidence {
      background-color: $red-2;
      color: $red-8;
    }

    .percent-voted {
      margin-left: 8px;
      font-size: 14px;
      color: $grey-5;
    }
  }
}

.code {
  font-family: monospace;
  color: $grey-9;
  background-color: $grey-1;
  font-size: 0.9em;
  padding: 2px 4px;
}

.highlight {
  opacity: 0.5;
  transition: all 0.2s;
  background-color: $primary-1;
}

.navbar-link {
  padding: 8px 4px;
  margin: 0 16px;
  text-transform: uppercase;
  font-weight: bold;
  color: $grey-5;
  letter-spacing: 2px;
  cursor: pointer;
  font-size: 14px;
  border-bottom: 3px solid rgba(0, 0, 0, 0);

  transition: 0.3s all;
}

.navbar-link:hover {
  color: $primary-7;
}

.navbar-link-active {
  color: $primary-6;
  border-bottom: 3px solid $primary-6;
}
