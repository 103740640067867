$primary-1: #e0e8f9;
$primary-2: #bed0f7;
$primary-3: #98aeeb;
$primary-4: #7b93db;
$primary-5: #647acb;
$primary-6: #4c63b6;
$primary-7: #4055a8;
$primary-8: #35469c;
$primary-9: #2d3a8c;
$primary-10: #19216c;

$grey-1: #f5f7fa;
$grey-2: #e4e7eb;
$grey-3: #cbd2d9;
$grey-4: #9aa5b1;
$grey-5: #7b8794;
$grey-6: #616e7c;
$grey-7: #52606d;
$grey-8: #3e4c59;
$grey-9: #323f4b;
$grey-10: #1f2933;

$red-1: #ffe3e3;
$red-2: #ffbdbd;
$red-3: #ff9b9b;
$red-4: #f86a6a;
$red-5: #ef4e4e;
$red-6: #e12d39;
$red-7: #cf1124;
$red-8: #ab091e;
$red-9: #8a041a;
$red-10: #610316;
