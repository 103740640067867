@import "./colours";

.modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 100;
}

.modal-body {
  background-color: white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  margin: 32px auto;
  width: 100%;
  max-width: 800px;

  padding: 24px;
  height: calc(100vh - 112px);
  overflow-y: auto;

  .close-btn {
    float: right;
    opacity: 0.7;
    cursor: pointer;
    transition: 0.4s opacity;
  }

  .close-btn:hover {
    opacity: 0.4;
  }

  h1 {
    margin: 12px 0 32px 0;
  }

  input[type="text"],
  select {
    background-color: $grey-2;
    border-bottom-color: $grey-3;
  }
}

.name-item {
  margin: 4px 0;

  .icon {
    cursor: pointer;
    opacity: 0.7;
    transition: 0.2s opacity;
    padding: 8px;
  }

  .icon:hover {
    opacity: 0.4;
  }

  .name {
    margin-left: 4px;
  }
}
